import { ethers } from 'ethers';
import game from "../hardhat/artifacts/contracts/game.sol/Game.json";
import zombieMint from "../hardhat/artifacts/contracts/zombieMint.sol/ZombieMint.json";
import carMint from "../hardhat/artifacts/contracts/carMint.sol/CarMint.json";
import { useState } from "react";
import { useEffect } from 'react';
import Getter from "./Getter.jsx";

const gameCon = "0xe654156E19a2687FEf1EE9a5fd34517C50a86757";
const zombieCon = "0xab88448B8aF0eDe77A219ACce9c67c2405477E62";
const carCon = "0x10BF5DBA788C8a078813C823Ab8d3c235c219F88";


const provider = new ethers.providers.Web3Provider(window.ethereum);

// get the end user
const signer = provider.getSigner();

//get the smart contract
const gameContract = new ethers.Contract(gameCon, game.abi, signer);
const zombieContract = new ethers.Contract(zombieCon, zombieMint.abi, signer);
const carContract = new ethers.Contract(carCon, carMint.abi, signer);

function Maim() {

    // Car functions.

    const setGame = async () => {    
        let strr = document.getElementById("carToGame").value;              //only owner
        const setG = await carContract.SPancake(strr);
        await setG.wait()
        .then(() => console.log(setG));
    }

    const mintCar = async () => {
        const mintC = await carContract.mintCar();
        await mintC.wait()
        .then(() => console.log(mintC));
    }

    const amountOfCars = async () => {          // should be fetched auto -- useEffect
        const amt = await carContract.amount();
        await amt.wait()
        .then(() => console.log(amt));
    }

    const checkPlay = async (x) => {
        const chk = await carContract.checkingTime(x);
        await chk.wait();
        if(chk) {
            document.write("true");
        }
    }

    const checkCarTime = async (x) => {
        const chkk = await carContract.daily(x);
        await chkk.wait()
        .then(() => console.log(chkk));
    }

    // Zombie functions

    const mintZombie = async () => {
        const mintZombie = await zombieContract.mintZombie();
        await mintZombie.wait()
        .then(() => console.log(mintZombie));
    }

    // Game functions

    //Setting.(3)
    const setZombieContract = async () => {
        let strr = document.getElementById("zombieContract").value;
        const setZmbC = await gameContract.setZombie(strr);
        await setZmbC.wait()
        .then(() => console.log(setZmbC));
    }

    const setCarContract = async () => {
        let strr = document.getElementById("carContract").value;
        const setCarC = await gameContract.setCar(strr);
        await setCarC.wait()
        .then(() => console.log(setCarC));
    }

    const setCoin = async () => {
        let strr = document.getElementById("coinContract").value;
        const setPaco = await gameContract.setter(strr);
        await setPaco.wait()
        .then(() => console.log(setPaco));
    }

    const attackGame = async (x) => {
        const atk = await gameContract.attack(x);
        await atk.wait()
        .then(() => console.log(atk));
    }

    const takeBrains = async (x) => {
        const takeBrains = await gameContract.withdraw(x);
        await takeBrains.wait()
        .then(() => console.log(takeBrains));
    }

    const cashingOut = async () => {
        const cash = await gameContract.cashOut();
        await cash.wait()
        .then(() => console.log(cash));
    }

    function prop() {
        attackGame(1);
    }

    return (
        <>
        <h3>Setting the Game contract</h3>
        <input type="text" id="coinContract"></input>
        <button onClick={setCoin}>CoinContract</button>
        <br></br>
        <input type="text" id="carContract"></input>
        <button onClick={setCarContract}>CarContract</button>
        <br></br>
        <input type="text" id="zombieContract"></input>
        <button onClick={setZombieContract}>ZombieContract</button>
        <br></br>
        <br></br>
        <h3>Setting the car contract</h3>
        <input type="text" id="carToGame"></input>
        <button onClick={setGame}>SetCarWithGame</button>
        <br></br>
        <br></br>
        <button onClick={mintZombie}>Mint a Zombie</button>
        <br></br>
        <br></br>
        <button onClick={mintCar}>Mint a Car</button>
        <br></br>
        <br></br>
        <button onClick={prop}>attack City</button>
        <Getter/>
        </>
        
    )


}

export default Maim;