import { ethers } from 'ethers';
import game from "../hardhat/artifacts/contracts/game.sol/Game.json";
import zombieMint from "../hardhat/artifacts/contracts/zombieMint.sol/ZombieMint.json";
import carMint from "../hardhat/artifacts/contracts/carMint.sol/CarMint.json";
import img1 from "./img/1.jpg";
import img2 from "./img/2.jpg";
import img3 from "./img/3.jpg";
import img4 from "./img/4.jpg";
import img5 from "./img/5.jpg";
import img6 from "./img/6.gif";
import { useState } from "react";
import { useEffect } from 'react';

const gameCon = "0xe654156E19a2687FEf1EE9a5fd34517C50a86757";
const zombieCon = "0xab88448B8aF0eDe77A219ACce9c67c2405477E62";
const carCon = "0x10BF5DBA788C8a078813C823Ab8d3c235c219F88";


const provider = new ethers.providers.Web3Provider(window.ethereum);

// get the end user
const signer = provider.getSigner();

//get the smart contract
const gameContract = new ethers.Contract(gameCon, game.abi, signer);
const zombieContract = new ethers.Contract(zombieCon, zombieMint.abi, signer);
const carContract = new ethers.Contract(carCon, carMint.abi, signer);

// global var
let smrtAtk = [];

function Pretty() {
    const [logged, setLog] = useState(false);
    const [rar, setRar] = useState([]);        // zombie rareza
    const [jars, setJar] = useState([]);       // 
    const [brains, setBrains] = useState();    //
    const [carRar, setcarRar] = useState([]);  // rareza carro

    // Cars
    const fetchingCars = async () => {
        const cars = await carContract.getOwnerCars()
        
        setcarRar(cars);
        console.log(cars);
    }

    // Zombies
    const fetchZombieRar = async () => {
        const rarrs = await zombieContract.getOwnerRarities();
        
        console.log(rarrs);
        setRar(rarrs);
    }

    // Brains
    const fetchBrainJar = async () => {
        const jarss = await gameContract.getBrainJars();
        const brainAmt = await gameContract.getBrainTotal();
        console.log(brainAmt);
        setBrains(brainAmt);
        console.log(jarss);
        setJar(jarss);
    }

    useEffect(() => {
        fetchBrainJar();
        fetchZombieRar();
        fetchingCars();
        
    }, []);

    const fetchBrains = async () => {
        const brr = await gameContract.getBrainJars();
        await brr.wait()
        .then(() => console.log(brr));
    } 

    console.log(rar)


return (
    <>
    <div>
        <h3> Zombies</h3>
    {rar.map((item, index) => {
            if(item == 1) {
            return (
            <img key={index} src={img1} >
            </img>)
            } else if (item == 2) {
                return (
                    <img key={index} src={img2}>
                    </img>)
            } else if (item == 0) {
                return (
                    <img key={index} src={img3}>
                    </img>)
            } else {
                return (
                    <img key={index} src={img4}>
                    </img>)
            }})}
            </div>
            <button onClick={fetchZombieRar}>try</button>
            <div>
                <h3> Cars</h3>
            {carRar.map((item, index) => {
            if(item == 1) {
            return (
            <img key={index} src={img6} >
            </img>)
            } else if (item == 2) {
                return (
                    <img key={index} src={img6}>
                    </img>)
            } else if (item == 0) {
                return (
                    <img key={index} src={img5}>
                    </img>)
            } else {
                return (
                    <img key={index} src={img5}>
                    </img>)
            }})}
            </div>
            </>
)
        }

export default Pretty;