import './App.css';
import Install from './components/Install';
import Crazy from './components/Crazy';


function App() {

  if (window.ethereum) {
  return <Crazy />;
} else {
  return <Install />;
}


}

export default App;
